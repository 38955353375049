export const projectTypes = {
  acs: [
    {
      id: "1",
      name: "Tető építés árak",
      price: 5000,
      unit: "m2",
      url: "/arak/teto-epites",
    },
    {
      id: "2",
      name: "Lapos tető szigetelés árak",
      price: 4000,
      unit: "m2",
      url: "/arak/lapos-teto-szigeteles",
    },
    {
      id: "3",
      name: "Tető javítás árak",
      price: 2000,
      unit: "m2",
      url: "/arak/teto-javitas",
    },
    {
      id: "4",
      name: "Tető felújítás árak",
      price: 3000,
      unit: "m2",
      url: "/arak/teto-felujitas",
    },
    {
      id: "5",
      name: "Tető cserepezés árak",
      price: 2000,
      unit: "m2",
      url: "/arak/teto-cserepezes",
    },
  ],
  burkolo: [
    {
      id: "1",
      name: "Laminált padló lerakás árak",
      price: 1750,
      unit: "m2",
      url: "/arak/laminalt-padlo-lerakas",
    },
    {
      id: "2",
      name: "Csempézés árak",
      price: 4500,
      unit: "m2",
      url: "/arak/csempezes",
    },
    {
      id: "3",
      name: "Térkövezés árak",
      price: 3750,
      unit: "m2",
      url: "/arak/terkovezes",
    },
    {
      id: "4",
      name: "Padlólap lerakás árak",
      price: 4500,
      unit: "m2",
      url: "/arak/terkovezes",
    },
    {
      id: "5",
      name: "Járólap lerakás árak",
      price: 5000,
      unit: "m2",
      url: "/arak/jarolap-lerakas",
    },
  ],
  epuletbontas: [
    {
      id: "1",
      name: "Épület bontás árak",
      price: 1200,
      unit: "m2",
      url: "/arak/epulet-bontas",
    },
    {
      id: "2",
      name: "Falbontás árak",
      price: 1800,
      unit: "m2",
      url: "/arak/falbontas",
    },
    {
      id: "4",
      name: "Betonbontás árak",
      price: 1700,
      unit: "m2",
      url: "/arak/betonbontas",
    },
  ],
  "gipszkarton-szereles": [
    {
      id: "1",
      name: "Gipszkarton szerelés árak",
      price: 2800,
      unit: "m2",
      url: "/arak/gipszkarton-szereles",
    },
    {
      id: "2",
      name: "Gipszkarton szigetelés árak",
      price: 500,
      unit: "m2",
      url: "/arak/gipszkarton-szigeteles",
    },
    {
      id: "3",
      name: "Álmennyezet készítés árak",
      price: 2875,
      unit: "m2",
      url: "/arak/almennyezet-keszites",
    },
    {
      id: "4",
      name: "Ablak beállítás árak",
      price: 5000,
      unit: "m2",
      url: "/arak/ablak-beallitas",
    },
    {
      id: "5",
      name: "Gipszkarton előtétfal készítés",
      price: 2500,
      unit: "m2",
      url: "/arak/gipszkarton-elotetfal-keszites",
    },
  ],
  "hang-teto-hoszigeteles": [
    {
      id: "1",
      name: "Homlokzati hőszigetelés árak",
      price: 3750,
      unit: "m2",
      url: "/arak/homlokzati-hoszigeteles",
    },
    {
      id: "2",
      name: "Tetőtér szigetelés árak",
      price: 800,
      unit: "m2",
      url: "/arak/tetoter-szigeteles",
    },
    {
      id: "3",
      name: "Dryvit hálózás árak",
      price: 1500,
      unit: "m2",
      url: "/arak/dryvit-halozas",
    },
    {
      id: "4",
      name: "Homlokzat festés árak",
      price: 1200,
      unit: "m2",
      url: "/arak/homlokzat-festes",
    },
  ],
  komuves: [
    {
      id: "1",
      name: "Glettelés árak",
      price: 700,
      unit: "m2",
      url: "/arak/gletteles",
    },
    {
      id: "2",
      name: "Ablak beépítés árak",
      price: 5000,
      unit: "m2",
      url: "/arak/ablak-beepites",
    },
    {
      id: "3",
      name: "Külső hőszigetelés árak",
      price: 5000,
      unit: "m2",
      url: "/arak/kulso-hoszigeteles",
    },
    {
      id: "4",
      name: "Ajtó csere árak",
      price: 10000,
      unit: "m2",
      url: "/arak/ajto-csere",
    },
    {
      id: "5",
      name: "Vakolás árak",
      price: 1750,
      unit: "m2",
      url: "/arak/vakolas",
    },
  ],
  villanyszerelo: [
    {
      id: "1",
      name: "Villanyszerelés árak",
      price: 4000,
      unit: "kiállás",
      url: "/arak/villanyszereles",
    },
    {
      id: "2",
      name: "Mosógép bekötés árak",
      price: 5000,
      unit: "darab",
      url: "/arak/mosogep-bekotes",
    },
    {
      id: "3",
      name: "Mérőhely szabványosítás árak",
      price: 60000,
      unit: "darab",
      url: "/arak/merohely-szabvanyositas",
    },
    {
      id: "4",
      name: "Sütő bekötés árak",
      price: 7500,
      unit: "darab",
      url: "/arak/suto-bekotes",
    },
    {
      id: "5",
      name: "Kismegszakító csere árak",
      price: 2000,
      unit: "darab",
      url: "/arak/kismegszakito-csere",
    },
  ],
  vizszerelo: [
    {
      id: "1",
      name: "Csaptelep cseréje árak",
      price: 7000,
      unit: "m2",
      url: "/arak/csaptelep-csereje",
    },
    {
      id: "2",
      name: "Bojler vízkőtelenítés árak",
      price: 15000,
      unit: "darab",
      url: "/arak/bojler-vizkotelenites",
    },
    {
      id: "3",
      name: "Vízóra csere árak",
      price: 6250,
      unit: "darab",
      url: "/arak/vizora-csere",
    },
    {
      id: "4",
      name: "Mosógép bekötés árak",
      price: 5000,
      unit: "m2",
      url: "/arak/mosogep-bekotes",
    },
    {
      id: "5",
      name: "Padlófűtés szerelés árak",
      price: 2250,
      unit: "m2",
      url: "/arak/padlofutes-szereles",
    },
  ],
  "szobafesto-tapetazo": [
    {
      id: "1",
      name: "új építésű, ####-es ház vakolt falainak szobafestése",
      description: "A fal állapota: új, vakolt",
      unit: "m2",
    },
    {
      id: "2",
      name: "új építésű, ####-es ház vakolt falainak glettelése",
      description: "A fal állapota: új, vakolt",
      unit: "m2",
    },
    {
      id: "3",
      name: "egy átlagos ####-es szoba tisztasági festése 2 rétegben",
      description:
        "A fal állapota: jó, van néhány szöglyuk, kisebb hajszálrepedések",
      unit: "m2",
    },
    {
      id: "4",
      name: "egy átlagos ####-es szoba egy falának tapétázása fotótapétával",
      unit: "m2",
      description: "A fal állapota: jó, festett",
    },
    {
      id: "5",
      name: "#### új gipszkarton falazat glettelése, festése 2 rétegben",
      unit: "m2",
      description: "A fal állapota: új",
    },
    {
      id: "6",
      name: "#### villanyszerelő utáni faljavítás",
      description: "A fal állapota: vissza lett vakolva a vésés hely",
      unit: "m2",
    },
  ],
};
