var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-primary-darker py-14 hidden md:flex flex-col items-center"},[_c('div',[_c('div',{staticClass:"flex align-middle justify-center pb-3 text-5xl"},[_c('IconComponent',{staticClass:"text-white",attrs:{"icon":"wrench"}})],1),_c('div',{staticClass:"flex align-middle justify-center text-white text-2xl"},[_vm._v(" "+_vm._s(_vm.$t("personList.professions"))+" ")])]),_c('div',{staticClass:"flex flex-row justify-center gap-10"},[(!_vm.isSuperprofikCz)?_c('div',{staticClass:"w-96"},[_c('section',[_c('h1',{staticClass:"text-2xl text-primary-lightest pb-8 text-center"},[_vm._v(" "+_vm._s(_vm.$t("professionLinks.professionGroups"))+" ")])]),_c('div',{staticClass:"grid grid-cols-2"},_vm._l((_vm.groups),function(array,index){return _c('ul',{key:index,class:{
            'text-center': _vm.groups.length === 1,
            'text-end': _vm.groups.length === 2 && index === 1,
          }},_vm._l((array),function(professionGroup){return _c('li',{key:`plpg${professionGroup.id}`,staticClass:"mb-2"},[_c('NuxtLink',{staticClass:"text-white hover:text-primary-lighter mr-auto",attrs:{"to":_vm.localePath({
                  name: 'professionsPage',
                  params: { professionGroup: professionGroup.seoName },
                })}},[_vm._v(" "+_vm._s(professionGroup.name)+" ")])],1)}),0)}),0)]):_vm._e(),_c('div',{staticClass:"w-96"},[_c('section',[_c('h1',{staticClass:"text-2xl text-primary-lightest pb-8 text-center"},[_vm._v(" "+_vm._s(_vm.$t("professionLinks.popularProfessions"))+" ")])]),_c('div',{staticClass:"grid",class:{
          'grid-cols-2': _vm.professionsArrays.length === 2,
        }},_vm._l((_vm.professionsArrays),function(professionArray,index){return _c('ul',{key:index,class:{
            'text-center': _vm.professionsArrays.length === 1,
            'text-end': _vm.professionsArrays.length === 2 && index === 1,
          }},_vm._l((professionArray),function(profession){return _c('li',{key:`plp${profession.id}`,staticClass:"mb-2"},[_c('NuxtLink',{staticClass:"text-white hover:text-primary-lighter mr-auto first-letter:capitalize whitespace-nowrap",attrs:{"to":_vm.localePath({
                  name: 'professionPage',
                  params: { profession: profession.seoName },
                })}},[_vm._v(" "+_vm._s(profession.name)+" ")])],1)}),0)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }