export const projects = [
  {
    professionSeoName: "szobafesto-tapetazo",
    projectTypeId: "1",
    id: "11",
    Szaki: "Lorinc",
    mateId: 171529,
    tasks: [
      {
        title: "mélyalapozás",
        price: 300,
      },
      {
        title: "glettelés",
        price: 1250,
      },
      {
        title: "csiszolás",
        price: 350,
      },
      {
        title: "festés két rétegben",
        price: 2000,
      },
    ],
  },
  {
    projectTypeId: "1",
    professionSeoName: "szobafesto-tapetazo",
    id: "12",
    Szaki: "Zsolt",
    mateId: 483381,
    tasks: [
      {
        title: "takarás papírral",
        price: 100,
      },
      {
        title:
          "durva glettelés - először 630 -as durva glettel majd finom glettel kétszer = Q3-as minőség  (3 réteg glett)",
        price: 2000,
      },
      {
        title: "csiszolás kétszer",
        price: 1000,
      },
      {
        title: "mélyalapozás",
        price: 300,
      },
      {
        title:
          "festés két rétegben - egy színnel 1500 Ft/m2, két színnel 1800 Ft/m2",
        price: 1800,
      },
      {
        title: "takarítás",
        price: 50,
      },
    ],
  },
  {
    projectTypeId: "1",
    professionSeoName: "szobafesto-tapetazo",
    id: "13",
    Szaki: "Norbi",
    mateId: 173166,
    tasks: [
      {
        title: "mélyalapozás",
        price: 400,
      },
      {
        title: "glettelés",
        price: 1800,
      },
      {
        title: "csiszolás",
        price: 150,
      },
      {
        title: "festés két rétegben",
        price: 1200,
      },
      {
        title: "takarás",
        price: 200,
      },
      {
        title: "takarítás",
        price: 35,
      },
    ],
  },
  {
    projectTypeId: "1",
    professionSeoName: "szobafesto-tapetazo",
    id: "14",
    Szaki: "Balazs",
    mateId: 562928,
    tasks: [
      {
        title: "mélyalapozás",
        price: 250,
      },
      {
        title: "glettelés 2 rétegben",
        price: 1200,
      },
      {
        title: "csiszolás - gépi csiszolás",
        price: 350,
      },
      {
        title: "festés két rétegben",
        price: 1200,
      },
      {
        title: "takarás",
        price: 400,
      },
    ],
  },
  {
    projectTypeId: "2",
    professionSeoName: "szobafesto-tapetazo",
    id: "15",
    Szaki: "Lorinc",
    mateId: 171529,
    tasks: [
      {
        title: "glettelés",
        price: 2000,
      },
    ],
  },
  {
    projectTypeId: "2",
    professionSeoName: "szobafesto-tapetazo",
    id: "16",
    Szaki: "Zsolt",
    mateId: 483381,
    tasks: [
      {
        title: "takarás papírral",
        price: 100,
      },
      {
        title:
          "durva glettelés - először 630 -as durva glettel majd finom glettel kétszer = Q3-as minőség  (3 réteg glett)",
        price: 2000,
      },
      {
        title: "csiszolás kétszer",
        price: 1000,
      },
    ],
  },
  {
    projectTypeId: "2",
    professionSeoName: "szobafesto-tapetazo",
    id: "17",
    Szaki: "Norbi",
    mateId: 173166,
    tasks: [
      {
        title: "kézi glettelés zsákos gipsszel 3 rétegben",
        price: 1800,
      },
    ],
  },
  {
    projectTypeId: "2",
    professionSeoName: "szobafesto-tapetazo",
    id: "18",
    Szaki: "Balazs",
    mateId: 562928,
    tasks: [
      {
        title: "glettelés 2 rétegben",
        price: 1600,
      },
    ],
  },
  {
    projectTypeId: "3",
    professionSeoName: "szobafesto-tapetazo",
    id: "19",
    Szaki: "Lorinc",
    mateId: 171529,
    tasks: [
      {
        title: "festés két rétegben, szöglyukak javításával",
        price: 3000,
      },
    ],
  },
  {
    projectTypeId: "3",
    professionSeoName: "szobafesto-tapetazo",
    id: "20",
    Szaki: "Zsolt",
    mateId: 483381,
    tasks: [
      {
        title: "festés két rétegben, szöglyukak javításával",
        price: 2000,
      },
    ],
  },
  {
    projectTypeId: "3",
    professionSeoName: "szobafesto-tapetazo",
    id: "21",
    Szaki: "Norbi",
    mateId: 173166,
    tasks: [
      {
        title: "festés két rétegben, szöglyukak javításával",
        price: 1600,
      },
      {
        title: "takarás",
        price: 200,
      },
    ],
  },
  {
    projectTypeId: "3",
    professionSeoName: "szobafesto-tapetazo",
    id: "22",
    Szaki: "Balázs",
    mateId: 242377,
    tasks: [
      {
        title: "festés két rétegben, szöglyukak javításával",
        price: 1200,
      },
      {
        title: "takarás",
        price: 400,
      },
    ],
  },
  {
    projectTypeId: "4",
    professionSeoName: "szobafesto-tapetazo",
    id: "23",
    Szaki: "Lorinc",
    mateId: 171529,
    tasks: [
      {
        title: "tapéta felragasztása",
        price: 3500,
      },
    ],
  },
  {
    projectTypeId: "4",
    professionSeoName: "szobafesto-tapetazo",
    id: "24",
    Szaki: "Zsolt",
    mateId: 483381,
    tasks: [
      {
        title: "makulatúrázás, kis glettelés és tapéta ragasztása",
        price: 5000,
      },
    ],
  },
  {
    projectTypeId: "4",
    professionSeoName: "szobafesto-tapetazo",
    id: "25",
    Szaki: "Norbi",
    mateId: 173166,
    tasks: [
      {
        title: "tapéta felragasztása",
        price: 3000,
      },
      {
        title: "takarás",
        price: 200,
      },
    ],
  },
  {
    projectTypeId: "4",
    professionSeoName: "szobafesto-tapetazo",
    id: "26",
    Szaki: "Balázs",
    mateId: 242377,
    tasks: [
      {
        title: "mélyalapozás",
        price: 250,
      },
      {
        title: "tapéta ragasztása",
        price: 4200,
      },
      {
        title: "takarás",
        price: 400,
      },
    ],
  },
  {
    projectTypeId: "5",
    professionSeoName: "szobafesto-tapetazo",
    id: "27",
    Szaki: "Lorinc",
    mateId: 171529,
    tasks: [
      {
        title: "glettelés és festés két rétegben",
        price: 3500,
      },
    ],
  },
  {
    projectTypeId: "5",
    professionSeoName: "szobafesto-tapetazo",
    id: "28",
    Szaki: "Zsolt",
    mateId: 483381,
    tasks: [
      {
        title:
          "hálózás a találkozásoknál, glettelés, mélyalapozás, festés két rétegben",
        price: 3000,
      },
    ],
  },
  {
    projectTypeId: "5",
    professionSeoName: "szobafesto-tapetazo",
    id: "29",
    Szaki: "Norbi",
    mateId: 173166,
    tasks: [
      {
        title: "glettelés Q3 minőségben, csiszolással együtt",
        price: 1600,
      },
      {
        title: "festés 2 rétegben, fehér színben ",
        price: 1200,
      },
    ],
  },
  {
    projectTypeId: "5",
    professionSeoName: "szobafesto-tapetazo",
    id: "30",
    Szaki: "Balazs",
    mateId: 562928,
    tasks: [
      {
        title: "mélyalapozás",
        price: 250,
      },
      {
        title: "glettelés 2 rétegben Q3 minőségben bandázsolással",
        price: 1200,
      },
      {
        title: "csiszolás - gépi csiszolás",
        price: 350,
      },
      {
        title: "festés két rétegben",
        price: 1200,
      },
      {
        title: "takarás",
        price: 400,
      },
    ],
  },
  {
    projectTypeId: "6",
    professionSeoName: "szobafesto-tapetazo",
    id: "31",
    Szaki: "Lorinc",
    mateId: 171529,
    tasks: [
      {
        title: "glettelés, csiszolás, festés",
        price: 3500,
      },
    ],
  },
  {
    projectTypeId: "6",
    professionSeoName: "szobafesto-tapetazo",
    id: "32",
    Szaki: "Zsolt",
    mateId: 483381,
    tasks: [
      {
        title: "kaparás, glettelés, csiszolás, festés",
        price: 2000,
      },
    ],
  },
  {
    projectTypeId: "6",
    professionSeoName: "szobafesto-tapetazo",
    id: "33",
    Szaki: "Norbi",
    mateId: 173166,
    tasks: [
      {
        title: "glettelés csiszolással együtt",
        price: 1150,
      },
      {
        title: "festés 2 rétegben, fehér színben ",
        price: 1200,
      },
    ],
  },
  {
    projectTypeId: "6",
    professionSeoName: "szobafesto-tapetazo",
    id: "34",
    Szaki: "Balazs",
    mateId: 562928,
    tasks: [
      {
        title: "mélyalapozás",
        price: 250,
      },
      {
        title: "glettelés 2 rétegben",
        price: 1200,
      },
      {
        title: "csiszolás",
        price: 350,
      },
      {
        title: "festés két rétegben",
        price: 1200,
      },
      {
        title: "takarás",
        price: 400,
      },
    ],
  },
];
