export const projectTypes = [
  {
    id: "1",
    name: "új építésű, ####-es ház vakolt falainak szobafestése",
    description: "A fal állapota: új, vakolt",
    unit: "m2",
  },
  {
    id: "2",
    name: "új építésű, ####-es ház vakolt falainak glettelése",
    description: "A fal állapota: új, vakolt",
    unit: "m2",
  },
  {
    id: "3",
    name: "egy átlagos ####-es szoba tisztasági festése 2 rétegben",
    description:
      "A fal állapota: jó, van néhány szöglyuk, kisebb hajszálrepedések",
    unit: "m2",
  },
  {
    id: "4",
    name: "egy átlagos ####-es szoba egy falának tapétázása fotótapétával",
    unit: "m2",
    description: "A fal állapota: jó, festett",
  },
  {
    id: "5",
    name: "#### új gipszkarton falazat glettelése, festése 2 rétegben",
    unit: "m2",
    description: "A fal állapota: új",
  },
  {
    id: "6",
    name: "#### villanyszerelő utáni faljavítás",
    description: "A fal állapota: vissza lett vakolva a vésés hely",
    unit: "m2",
  },
];
