<template>
  <div class="testimonials-wrapper">
    <div class="testimonials">
      <transition mode="out-in" name="slide-fade">
        <div :key="getCurrentTestimonal.id" class="testimonial">
          <div class="user mr-5">
            <img
              v-lazy-load :data-src="imageSource(getCurrentTestimonal.img)"
              data-not-lazy
              class="user-image"
              width="56px"
              height="56px"
              alt=""
            />
            <p class="user-name text-white">
              {{ getCurrentTestimonal.name }}
            </p>
          </div>
          <div class="textbox block text-white">
            <p class="text">"{{ getCurrentTestimonal.text }}"</p>
          </div>
        </div>
      </transition>
    </div>

    <div class="paginator">
      <div
        v-for="(page, index) in testimonials"
        :key="index"
        class="page"
        :class="{ active: index == pageIndex }"
        @click="setPageIndex(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageIndex: 0,
      pagerTimeout: null,
      testimonials: [
        {
          id: 0,
          name: "Máté Gyula",
          img: "testimonials/mate_gyula.jpg",
          text: "Villámgyors, készséges, hatékony. Én most találkoztam először a rendszeretekkel, meg kell mondjam, le vagyok nyűgözve. Profi a portál, a mögötte levő közvetítő rendszer, és mint kiderült, az ügyfélszolgálat is! Gratulálok és további sok sikert!",
        },
        {
          id: 1,
          name: "Bodor Tibor",
          img: "testimonials/bodor_tibor.jpg",
          text: "Én csak megköszönni tudom a segítséget, a munkát, a gyors és szakszerű ügyintézést. Az oldal nagyon jó, tökéletesen működik !!!!",
        },
        {
          id: 2,
          name: "Kiss Gábor",
          img: "testimonials/kiss_gabor.jpg",
          text: "Kérdésemre egyértelmű, világos választ kaptam. A válasz stílusa emberi, intelligens, partneri, segítőkész. Köszönöm!",
        },
        {
          id: 3,
          name: "Szombathelyi Krisztián",
          img: "testimonials/szombathelyi_krisztian.jpg",
          text: "Tetszik, hogy mindig gyorsan válaszolnak és hogy vevők a humorra..",
        },
      ],
    };
  },
  computed: {
    getCurrentTestimonal() {
      return this.testimonials[this.pageIndex];
    },
  },
  mounted() {
    this.setPageIndex();
  },
  beforeDestroy() {
    clearInterval(this.pagerTimeout);
    this.pagerTimeout = null;
  },
  methods: {
    imageSource(image) {
      return require(`~/assets/${image}`);
    },
    setPageIndex(index = 0) {
      if (this.pagerTimeout) {
        clearInterval(this.pagerTimeout);
        this.pagerTimeout = null;
      }
      this.pageIndex = index;
      this.pagerTimeout = setInterval(() => {
        if (this.pageIndex < this.testimonials.length - 1) {
          this.pageIndex++;
        } else {
          this.pageIndex = index;
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.testimonials-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .testimonials {
    min-height: 100px;
    display: flex;
    align-items: center;
    position: relative;

    .testimonial {
      display: flex;
      align-items: center;
      // position:absolute;

      .user {
        .user-name {
          opacity: 0.7;
          font-size: 12px;
          max-width: 70px;
        }
        .user-image {
          width: 56px;
          height: 56px;
          min-width: 56px;
          min-height: 56px;
          border-radius: 100%;
        }
      }
      .textbox {
        flex-grow: 0;
        flex-shrink: 1;
        background: rgba(black, 0.5);
        padding: 13px;
        border-radius: 10px;
        font-style: italic;
        font-size: 12px;
        max-width: 450px;
        color: white;
      }
    }
  }

  .paginator {
    display: inline-grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fit, 8px);
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    .page {
      width: 100%;
      height: 8px;
      background: white;
      border-radius: 100%;
      opacity: 0.2;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
